<template>
  <div class="">
    <HeaderVue heading="Customers " class="pb-3">
      <template v-slot:buttons>
        <div class="d-flex align-items-center">
          <!-- <div class="download-title mr-4">Download .CSV</div> -->
          <div class="">
            <!--  -->
            <!-- exportToExcel -->
            <Button @click="$refs.exportLeads.openExportLeadsModal()">
              <i class="fa-solid fa-file-export pr-2"></i> Export
              Customers</Button
            >

            <a ref="downloadLink" style="display: none"></a>
          </div>
        </div>
      </template>
    </HeaderVue>
    <div class="card border-radius">
      <div class="card-body" style="overflow: auto">
        <div class="">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-end mb-2">
                <div class="d-flex align-items-center mx-2" v-if="isLoading">
                  <b-spinner
                    small
                    label="Small Spinner"
                    style="color: #4d1b7e"
                  ></b-spinner
                  ><span class="ml-2">Loading Leads...</span>
                </div>
                <button title="Filter" class="filter-btn" v-b-toggle.collapse-1>
                  <span class="d-flex">
                    <b-icon icon="search"></b-icon>
                    <b-icon icon="filter"></b-icon>
                  </span>
                </button>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <b-collapse id="collapse-1" visible>
                <FilterBox
                  v-model="titleInput"
                  placeholder="Search here..."
                  
                ></FilterBox>

                <div class="">
                  <div class="d-flex my-2 flex-wrap" style="gap: 5px">
                    <FilterSelect
                      :staticOptions="quizzes"
                      :isDynamic="false"
                      ref="quiz"
                      :selectedArray="selectedQuizIdsHandle"
                      filterFor="quizzes"
                      @update="updateQuizID"
                    ></FilterSelect>
                    <FilterSelect
                     :isDynamic="false"
                      :isCalender="true"
                      @updateValues="updateValues"
                    ></FilterSelect>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>

          <!-- -->
          <b-skeleton-table
            v-if="isLoadingFirstTime"
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true }"
          ></b-skeleton-table>
          <b-table
            row-class="custom-row-class"
            :responsive="true"
            v-else-if="items.length > 0"
            hover
            head-class="text-center"
            class="bg-table-heading bg-table-heading"
            @row-clicked="
              (e) => {
                $refs.leadInfoRef.getLeadInfo(e);
              }
            "
            :fields="fields"
            :items="items"
            show-empty
          >
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                v-model="selectAllLeads"
                @input="toggleSelectAllLeads()"
                class="checkbox"
              ></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                size="lg"
                v-model="selectedLeads"
                :value="data.item.id"
                class="checkbox"
              ></b-form-checkbox>
            </template>
            <template #cell(letter)="data">
              <div
                class="text-uppercase d-flex align-items-center justify-content-center"
                style="height: 40px; display: block; width: 40px; color: white"
                :style="[{ background: randomColors[data.index] }]"
              >
                {{ checkFirstCharacter(data.item) }}
              </div>
            </template>

            <template #cell(created_at)="data">
              {{ formattedDate(data.item.created_at) }}
            </template>
          </b-table>
          <template v-else>

<div class="d-flex justify-content-center align-items-center"  >

<EmptyTable >
<template #title>
No lead found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
          <QuizellPagination
            :total-rows="totalRows"
            :currentPage="currentPage"
            :per-page="perPage"
            v-if="items.length"
            @update="updatePagination"
            @UpdatePerPage="updatePerPage"
          ></QuizellPagination>
        </div>
      </div>
    </div>

    <ExportLeads
      ref="exportLeads"
      @export="exportToExcel($event)"
      :isFileDownload="isLoadingExport"
      :selected="selectedLeads.length"
      :exportProductFilter="filter"
    ></ExportLeads>
    <LeadInformationModalVue ref="leadInfoRef" />
    <DeleteFooterModal
    ref="deleteLeads"
      name="leads"
      @click="DeleteLeads"
      :isLoading="deleteLeadLoading"
      :item-length="selectedLeads.length"
      :isDuplicateButton="false"
    ></DeleteFooterModal>
  </div>
</template>
<script>
import { debounce } from "lodash";
import Button from "../Layout/Button.vue";
import axios from "axios";
import DeleteFooterModal from "../Layout/DeleteFooterModal.vue";
import FilterSelect from "../Layout/FilterSelect.vue";
import FilterBox from "../Layout/FilterBox.vue";
import QuizellPagination from "../Layout/QuizellPagination.vue";
import EmptyTable from "../Layout/EmptyTable.vue";
export default {
  components: {
    Button,
    QuizellPagination,
    FilterBox,
    FilterSelect,
    DeleteFooterModal,
    EmptyTable,
    LeadInformationModalVue: () =>
      import(
        /* webpackChunkName: "LeadInformationModalVue" */ "./LeadInformationModal.vue"
      ),
    ExportLeads: () =>
      import(/* webpackChunkName: "ExportLeads" */ "./ExportLeads.vue"),
    HeaderVue: () =>
      import(/* webpackChunkName: "HeaderVue" */ "../Layout/Header.vue"),
  },
  watch: {
    "filter.quizIds"(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.handleSearchDebounced();
      }
    },
    titleInput() {
      this.handleSearchDebounced();
    },
    allLeads({ selected, all }) {
      if (all.every((lead) => selected.includes(lead.id)) && all.length > 0)
        this.selectAllLeads = true;
      else this.selectAllLeads = false;
    },

    "$route.query.title"(newValue) {
      this.filter.title = newValue || "";
    },
    "$route.query.start"(newValue) {
      this.filter.start_date = newValue || "";
    },
    "$route.query.end"(newValue) {
      this.filter.start_end = newValue || "";
    },
    "$route.query.quizIds"(newValue) {
      this.filter.quizIds = newValue ? newValue.split(",") : [];
    },
    "$route.query.page"(newValue) {
      this.currentPage = newValue ? parseInt(newValue) : 1;
    },
    "$route.query.perPage"(newValue) {
      this.perPage = newValue ? parseInt(newValue) : 10;
    },
  },
  computed: {
    selectedQuizIdsHandle() {
      return this.quizzes.filter((quiz) => {
        return this.filter.quizIds.includes(quiz.id.toString());
      });
    },
    minDate() {
      let dateString = this.filter.start_date;
      let timestamp = Date.parse(dateString);
      return new Date(timestamp);
    },
    allLeads() {
      return { selected: this.selectedLeads, all: this.items };
    },
  },
  created() {
    const query = this.$route.query;
    this.filter.title = this.titleInput = query.title || "";
    this.filter.start_date = query.start || "";
    this.filter.end_date = query.end || "";
    this.filter.quizIds = query.quizIds ? query.quizIds.split(",") : [];
    this.currentPage = parseInt(query.page) || 1;
    this.perPage = parseInt(query.perPage) || 10;
  },
  data() {
    return {
      titleInput: "",

      selectAllLeads: false,
      filter: {
        title: "",
        quizIds: [],
        start_date: "",
        end_date: "",
        fields:'id,full_name,email,created_at'
      },
      isLoadingExport: false,
      searchQuiz: "",
      quizzes: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 20,
      randomColors: [],
      deleteLeadLoading: false,
      selectedLeads: [],
      isLoading: false,
      loadingFilter: false,
      isLoadingFirstTime: true,
      fields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "data-Table cursor-pointer align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "letter",
          label: "",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "full_name",
          label: "Name",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "email",
          label: "Email",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "created_at",
          label: "Date",
          tdClass: "data-Table align-middle cursor-pointer",
          thClass: "bg-table-heading",
        },
      ],
      items: [],
    };
  },
  methods: {
    updatePerPage(e) {
      this.currentPage = 1;
      console.log(e);
      this.perPage = e;
      this.applyFilters();
    },
    updatePagination(e) {
      this.currentPage = e;
      this.applyFilters();
    },
    updateTag(e) {
      this.tagInput = e.title;
      this.applyFilters();
    },
    updateQuizID(e) {
      this.filter.quizIds = e.map((col) => col.id);
      this.currentPage = 1;
      this.applyFilters();
    },

    applyFilters() {
      const queryParams = {};
      if (this.titleInput) queryParams.title = this.titleInput;
      this.filter.title = this.titleInput;
      if (this.filter.start_date) queryParams.start = this.filter.start_date;
      if (this.filter.end_date) queryParams.end = this.filter.end_date;
      if (this.filter.quizIds.length > 0)
        queryParams.quizIds = this.filter.quizIds.join(",");
      queryParams.perPage = this.perPage;
      queryParams.page = this.currentPage;
      this.$router.replace({ query: queryParams });
      this.getLeads();
    },
    updateValues(date) {
      this.filter.start_date = date.startDate;
      this.filter.end_date = date.endDate;

      this.applyFilters();
    },
    toggleSelectAllLeads() {
      if (this.selectAllLeads)
        this.selectedLeads = [
          ...new Set([
            ...this.selectedLeads,
            ...this.items.map((tag) => tag.id),
          ]),
        ];
      else this.selectedLeads = [];
    },
    formattedDate(inputDate) {
      if (!inputDate) return "";
      let parts = inputDate.split(" ");
      let datePart = parts[0];
      let dateParts = datePart.split("-");
      let convertedDate =
        dateParts[0] + "-" + dateParts[1] + "-" + dateParts[2];
      return convertedDate;
    },

    clearFilter() {
      this.filter = {
        title: "",
        quizIds: [],
        start_date: "",
        end_date: "",
      };
      this.getLeads();
    },
    async exportToExcel(options) {
      const method =
   
        options.insight == "withoutQuiz" ? "/leads/export" : "/leads/export-with-answers";
      // eslint-disable-next-line no-unreachable
      let filter = {
        file_type: "xlsx",
      };
      if (options.category == "filtered") {
        Object.keys(this.filter).forEach((key) => {
          if (key == "quiz_ids" && this.filter["quizIds"].length > 0)
            filter[key] = this.filter["quizIds"];
          else if (this.filter[key]) {
            filter[key] = this.filter[key];
          }
        });
      } else if (options.category == "selected") {
        filter.leadIds = this.selectedLeads;
      }
      this.isLoadingExport = true;
      try{
      let response1= await axios.post(method,filter,{
          responseType: 'blob', // Specify the response type here
        })
      const blob = new Blob([response1.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
const url = window.URL.createObjectURL(blob);
const downloadLink = this.$refs.downloadLink;
downloadLink.href = url;
downloadLink.download = "customers.xlsx";
downloadLink.click();
window.URL.revokeObjectURL(url);
}
catch(e){
  console.log(e)
  throw e
}
finally{
  this.isLoadingExport = false;
}
      
    },
    async DeleteLeads() {
      if (this.selectedLeads.length) {
        this.deleteLeadLoading = true;
        await axios
          .post(`/deleteUserLeads`, { ids: JSON.stringify(this.selectedLeads) })
          .then(() => {
            this.$toasted.show("Leads are successfully deleted", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            this.selectedLeads = [];
            this.$refs.deleteLeads.close()
            this.getLeads();
          })
          .catch((error) => {
            if (error) {
              this.$toasted.show("Error occurred", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
            }
            throw error;
          });
        this.deleteLeadLoading = false;
      }
    },
    async getLeads() {
      this.isLoading = true;

      await axios
        .post("/loadUserAllLeads", {
          page: this.currentPage,
          per_page: this.perPage,
          ...this.filter,
        })
        .then((response) => {
          if (response.data.data.data) {
            this.items = response.data.data.data;
            this.totalRows = response.data.data.total;
            this.generateRandomDarkColors();
          }
        })
        .catch((e) => console.log(e))
        .finally(() => (this.isLoading = false));
      this.isLoadingFirstTime = false;
    },
    checkFirstCharacter(item) {
      if (item.full_name) return item.full_name.charAt(0).toUpperCase();
      else if (item.email) return item.email.charAt(0).toUpperCase();
      return "Q";
    },
    async getFilter() {
      this.loadingFilter = true;
      axios
        .post("/filter", { page_name: "lead" })
        .then((response) => {
          this.quizzes = response.data.data.quizzes;
        })
        .catch((e) => console.log(e));
      this.loadingFilter = false;
    },
    generateRandomDarkColors() {
      this.randomColors = [];
      let letters = "0123456789ABCDEF";
      let numItems = this.items.length;

      for (var i = 0; i < numItems; i++) {
        let color = "#";

        for (var j = 0; j < 6; j++) {
          let randomIndex = Math.floor(Math.random() * letters.length);
          color += letters[randomIndex];
        }

        this.randomColors.push(color);
      }
    },

    handleSearchDebounced: debounce(function () {
      this.applyFilters();
    }, 500),
  },
  async mounted() {
    await this.getFilter();
    await this.getLeads();
  },
};
</script>

<style scoped>
.search-text:focus-visible {
  outline: none;
}
.search-text:active + .fa-search {
  color: #4d1b7e;
}
.tag {
  background: #bfbfc0;
  border-radius: 20px;
  border: 1px dashed;
  font-size: 14px;
}
.icon {
  color: #949495;
  transition: 0.5s ease all;
}
.icon:hover {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.search {
  border-radius: 6px;
  border: 0.8px solid #b3afb6;
}
.search:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.cursor-pointer {
  cursor: pointer;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.floatingDiv {
  position: fixed;
  top: auto;
  bottom: 20px;
  width: 90%;
}
.floatingDiv .InnerDiv {
  z-index: 999 !important;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0rem 0.125rem 0.25rem #1f21241a, 0rem 0.0625rem 0.375rem #1f21240d,
    0rem 0.25rem 1.125rem -0.125rem #1f212414,
    0rem 0.75rem 1.125rem -0.125rem #1f212426;
  padding: 10px;
}
.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}
.download-btn {
  border-radius: 4px;
  background: rgba(77, 27, 126, 0.2);
}
.download-title {
  color: #1f1f28;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.loader {
  min-height: 40px;
}
.bg-table-heading bg-table-heading >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.bg-table-heading bg-table-heading >>> thead tr {
  border-radius: 10px;
  cursor: pointer;
}
.bg-table-heading bg-table-heading >>> thead th {
  border: none;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.dropdown >>> .btn-danger {
  color: #4d1b7e;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.dropdown >>> .btn-danger:active {
  color: #3f1768;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.date >>> #Starting,
.date >>> #ending {
  /* color: #3f1768; */
  color: #4d1b7e;
}
.collapse-title {
  font-size: 18px;
  font-weight: 500;
}
.custom-row-class:hover {
  cursor: pointer; /* Change this to the desired cursor style */
}

.collapsed > .collapse-title > .when-opened,
:not(.collapsed) > .collapse-title > .when-closed {
  display: none;
}

.btn-filter {
  border-radius: 24px;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#dropdown-form {
}
#dropdown-form >>> .b-dropdown-form {
  padding: 0.25rem;
}
#dropdown-form >>> .QuizzesDropdownContainer {
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}
.filter-btn {
  background: #fff;
  border: navajowhite;
  box-shadow: 0 -0.0625rem 0 0 #b5b5b5 inset, 0 0 0 0.0625rem #0000001a inset,
    0 0.03125rem 0 0.09375rem #fff inset;
  border-radius: 0.5rem;
  padding: 5px 11px;
}
.filter-btn:focus {
  box-shadow: 0 -0.0625rem 0 0 #b5b5b5 inset, 0 0 0 0.0625rem #0000001a inset,
    0 0.03125rem 0 0.09375rem #fff inset !important;
}
.filter-btn:active {
  box-shadow: -0.0625rem 0 0.0625rem 0 rgba(26, 26, 26, 0.122) inset,
    0.0625rem 0 0.0625rem 0 rgba(26, 26, 26, 0.122) inset,
    0 0.125rem 0.0625rem 0 #1a1a1a33 inset !important;
}
</style>
